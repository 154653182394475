import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Avatar, Tabs, Row, Col, Space, Empty } from "antd";
import {
	MailOutlined,
	MobileOutlined,
	StarFilled,
	UserOutlined,
	GlobalOutlined,
} from "@ant-design/icons";
import { parsePhoneNumber } from "libphonenumber-js";
import { onSnapshot, doc } from "firebase/firestore";

import db from "../../data/Firebase";
import Title from "../layout/Title";
import InfluencerInfo from "./InfluencerInfo";
import useTranslation from "../../intl/useTranslation";
import {
	getActiveInfluencers,
	editActiveInfluencers,
	getInfluencer,
} from "./influencersSlice";
import removeTrailingParam from "../../lib/removeTrailingParam";
import formatRating from "../../lib/formatRating";
import DeleteInfluencer from "./DeleteInfluencer";
import Details from "./Details";
import networks, { activeNetworks } from "../../data/networks";
import NetworkTab from "./NetworkTab";

const ActiveInfluencer = ({ influencerId }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const location = useLocation();

	const [notFound, setNotFound] = useState(false);

	const influencer = useSelector((state) => getInfluencer(state, influencerId));
	const influencers = useSelector(getActiveInfluencers);

	useEffect(() => {
		if (
			influencer === null &&
			typeof influencerId !== "undefined" &&
			!notFound
		) {
			onSnapshot(doc(db, "influencers", influencerId), (doc) => {
				if (doc.exists && doc.data()) {
					dispatch(
						editActiveInfluencers([
							...(influencers !== null ? influencers : []),
							doc.data(),
						])
					);
				} else {
					setNotFound(true);
				}
			});
		}
	}, [influencer, influencers, dispatch, influencerId, notFound]);

	useEffect(() => {
		setNotFound(false);
	}, [influencerId]);

	if (influencer === null && !notFound)
		return (
			<>
				<Title
					title={<>Influencer</>}
					onBack={() => navigate(removeTrailingParam(location.pathname))}
				/>
				Loading
			</>
		);

	if (notFound)
		return (
			<>
				<Title
					title={<>Influencer</>}
					onBack={() => navigate(removeTrailingParam(location.pathname))}
				/>
				<Empty description={t("influencers.notFound")} />
			</>
		);

	return (
		<>
			<Title
				title={
					<>
						<Avatar
							size="large"
							src={influencer.avatar}
							icon={<UserOutlined />}
						/>
						{`${influencer.firstname} ${influencer.lastname}`}
					</>
				}
				extra={
					<span className="influencer-active-rating">
						<StarFilled />{" "}
						{influencer !== null && formatRating(influencer.rating)}
					</span>
				}
				onBack={() => navigate(removeTrailingParam(location.pathname))}
				small
			/>
			<div className="influencer-informations">
				<Row gutter={[16, 16]}>
					<Col>
						<a href={"mailto:" + influencer.email}>
							<InfluencerInfo icon={<MailOutlined />} data={influencer.email} />
						</a>
					</Col>
					<Col>
						<a href={"tel:" + influencer.phoneNumber}>
							<InfluencerInfo
								icon={<MobileOutlined />}
								data={parsePhoneNumber(
									influencer.phoneNumber
								).formatInternational()}
							/>
						</a>
					</Col>
					<Col>
						<InfluencerInfo
							icon={<GlobalOutlined />}
							data={t("login." + influencer.language)}
						/>
					</Col>
				</Row>
			</div>
			<Tabs
				className="influencer-tab"
				defaultActiveKey="networks"
				items={[
					{
						label: t("influencers.details"),
						key: "details",
						children: <Details influencer={influencer} />,
					},
				]}
			/>
			<Tabs
				className="influencer-tab"
				defaultActiveKey="networks"
				items={networks
					.filter((network) => activeNetworks(influencer).includes(network.id))
					.map((network) => ({
						label: network.label,
						key: network.id,
						children: <NetworkTab influencer={influencer} network={network} />,
					}))}
			/>
			<Space className="influencer-actions">
				<DeleteInfluencer influencer={influencer} />
			</Space>
		</>
	);
};

export default ActiveInfluencer;
