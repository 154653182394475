import { Result, List, Button, Spin, Modal, Typography } from "antd";
import useTranslation from "../../intl/useTranslation";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import { fbFunctions } from "../../data/Firebase";
import { httpsCallable } from "firebase/functions";
import { useState } from "react";
import moment from "moment";

const { Text } = Typography;

const AuditPdfs = ({ influencer, network, pdfOpen, setPdfOpen }) => {
	const { t } = useTranslation();

	const [newReportLoading, setNewReportLoading] = useState(false);
	const [newReportRequested, setNewReportRequested] = useState(false);

	const pdfReports = influencer[network].pdfReports;

	const createPdfReport = async () => {
		setNewReportLoading(true);
		setNewReportRequested(true);
		const pdfReportFunction = httpsCallable(fbFunctions, "pdfReportRequest");
		await pdfReportFunction({ influencerId: influencer.id, network });
		setNewReportLoading(false);
	};

	const sortedPdfReports =
		typeof pdfReports === "object"
			? Object.entries(pdfReports).sort(([dateA], [dateB]) =>
					moment(dateB).diff(moment(dateA))
			  )
			: null;

	return (
		<Modal
			title={t("influencers.pdfReportsTitle")}
			open={pdfOpen}
			footer={
				<>
					<Text type="secondary" style={{ float: "left" }}>
						{t("influencers.pdfReportsInfo")}
					</Text>
					<Button
						type="primary"
						onClick={createPdfReport}
						loading={newReportLoading}
						disabled={newReportRequested}
					>
						{t("influencers.pdfReportsNewButton")}
					</Button>
				</>
			}
			onCancel={() => setPdfOpen(false)}
		>
			{!pdfReports || pdfReports.length === 0 ? (
				<Result icon={<></>} subTitle={"No PDF reports available"} />
			) : (
				<List
					itemLayout="horizontal"
					dataSource={sortedPdfReports}
					bordered
					renderItem={([date, url]) => (
						<List.Item
							actions={[
								url === "loading" ? (
									<Spin
										style={{ display: "block" }}
										indicator={
											<LoadingOutlined style={{ fontSize: 24 }} spin />
										}
									/>
								) : (
									<Button
										type="primary"
										icon={<DownloadOutlined />}
										onClick={() =>
											window.open(
												url as string,
												"_blank",
												"noopener,noreferrer"
											)
										}
									>
										{t("Download")}
									</Button>
								),
							]}
						>
							<List.Item.Meta title={moment(date).format("DD/MM/YYYY")} />
						</List.Item>
					)}
				/>
			)}
		</Modal>
	);
};

export default AuditPdfs;
